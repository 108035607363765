import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { ChangeEvent, useState } from 'react';

import { Container } from '@mui/system';

import { useDatePicker } from '../../../components/DatePicker';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { useTranslation } from '../../../hooks';
import {
  convertUserTimezoneToDbTimezone,
  toDBTime
} from '../../../utils/helper';
import {
  Ifield,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

function ViewFilter({
  setIsSearched,
}: {
  setIsSearched: (isSearched: boolean) => void;
}) {
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifield>(initFields);
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters({
    isDefaultExpanded: true,
  });

  const DateObj = {
    Month: useDatePicker({
      start: {
        defaultDate: subMonths(new Date(), 1),
      },
      end: {
        defaultDate: subMonths(new Date(), 1),
      },
      inputFormat: "yyyy-MM",
    }),
  };

  const zusParams = useZusParams();

  const getParams: () => IsearchParam = () => {
    const { ...rest } = fields;

    const { start: monthFrom, end: monthTo } = DateObj.Month;

    const settlementDateFrom = toDBTime(
      convertUserTimezoneToDbTimezone(startOfMonth(new Date(monthFrom)))
    );

    const settlementDateTo = toDBTime(
      convertUserTimezoneToDbTimezone(endOfMonth(new Date(monthTo)))
    );

    return {
      ...initZusParams,
      ...rest,
      settlementDateFrom,
      settlementDateTo,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    setIsSearched(true);
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.Month.resetDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (
      e:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.month,
      filter: <DateObj.Month.Picker type="date" views={["year", "month"]} />,
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}

export default ViewFilter;
