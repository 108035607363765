import errorCode from "../../utils/errorCode.json";
import countryCode from "./countryCode.json";
import mccCode from "./mccCode.json";
import payMethodCode from "./payMethodCode.json";

const json = {
  noPrefix: {}, // for notice no prefix set when use zustand translate prefix
  errorCode: errorCode.en,
  countryCode: countryCode,
  payMethodCode: payMethodCode,
  mccCode: mccCode,

  menu: {
    version: "Version",
    homepage: "Home Page",
    dashboard: "Welcome to Credit Manager!",
    accountSetting: "Account Setting",
    creditManagement: "Credit Management",
    customerAccount: "Customer Account",
    merchantWallet: "Merchant Wallet",
    customerWallet: "Customer Wallet",
    feeWallet: "Fee Wallet",
    merchantDepositRequest: "Merchant Deposit Request",
    walletTransaction: "Wallet Transaction",
    creditAdjustmentRequest: "Credit Adjustment Request",
    repaymentSchedule: "Repayment Schedule",
    preCreateCardRequest: "Pre-Create Card Request",
    cardList: "Card List",
    cardOrderRequest: "Card Order Request",
    balanceAdjustmentRequest: "Balance Adjustment Request",
    cardTransaction: "Card Transaction",
    report: "Reports",
    dailyTransaction: "Daily Transactions",
    toggleFilterButtonCollapse: "Collapse",
    toggleFilterButtonExpand: "Expand",
    toggleBtnCollapse: "Collapse",
    toggleBtnExpand: "Expand",
    dataAnalysis: "Data Analysis",
    consumption: "Consumption",
    byProgramConsumption: "By Program",
    byCountryConsumption: "By Country",
    byPfhNoConsumption: "By PFH No.",
    byMccConsumption: "By MCC",
    byPaymentMethodConsumption: "By Payment Method",
    withdraw: "Withdraw",
    byProgramWithdraw: "By Program",
    byPfhNoWithdraw: "By PFH No.",
    byCountryWithdraw: "By Country",
    customerSpendingReport: "Customer Spending Report",
    transactionPostMonitoringReport: "Transaction Post Monitoring Report",
    suspiciousTransactionReport: "Suspicious Transaction Report",
  },

  common: {
    welcome: "Welcome",
    logout: "Logout",
    confirm: "Confirm",
    edit: "Edit",
    cancel: "Cancel",
    close: "Close",
    start_date: "Start Date",
    end_date: "End Date",
    no_data_export: "No data for export",
    phSelection: "Please select %{fieldName}",
    phInputField: "Please enter %{fieldName}",
    phSelectionOptional: "Please select %{fieldName} (Optional)",
    phInputFieldOptional: "Please enter %{fieldName} (Optional)",
    optional: "Optional",
    search: "Search",
    reset: "Reset",
    export: "Export",
    table: "Table",
    details: "Details",
    successCreate: "Created %{target} Successfully",
    download: "Download",
    autoLogoutAlert:
      "Automatically logged out because another account is already logged in from same browser",
    logoutAlert: "You have been logged out",
    alert_no_permission: "No relevant permission",
    no_data_found: "No Data in Database",
    copied: "Copied!",
    yes: "Yes",
    no: "No",
    cardProfileName: "Card Profile Name",
    noRecord: "No Record",
    collapse: "Collapse",
    expand: "Expand",
    reload: "Reload",
    filter: "Filter",
    custom: "Custom",
    programName: "Program Name",
    subType: "Sub-Type",
    selectAdjustmentTypeFirst: "Please select Adjustment Type first",
  },

  validation: {
    selectRequired: "Please select %{fieldName}",
    inputRequired: "Please enter %{fieldName}",
    shouldBeLargerThanZero: "%{fieldName} must be larger than 0",
    invalidDecimal:
      "The decimal place of %{currency} cannot be more than %{decimal}",
    inputLength: "%{fieldName} must be %{min} %{max} characters",
  },

  component: {
    typeToSearch: "Type to Search",
    noRecords: "No relevant record exists",
    itemTotalStart: "",
    itemTotalEnd: " item(s) in total",
    clickSearchToViewRecords: "Please click SEARCH for searching records",
  },

  enumConstants: {
    en: "English",
    zh_cn: "简中",
    timezone0: "+0",
    timezone1: "+1",
    timezone2: "+2",
    timezone3: "+3",
    timezone4: "+4",
    timezone5: "+5",
    timezone6: "+6",
    timezone7: "+7",
    timezone8: "+8",
    timezone9: "+9",
    timezone10: "+10",
    timezone11: "+11",
    timezone12: "+12",
    timezone13: "-11",
    timezone14: "-10",
    timezone15: "-9",
    timezone16: "-8",
    timezone17: "-7",
    timezone18: "-6",
    timezone19: "-5",
    timezone20: "-4",
    timezone21: "-3",
    timezone22: "-2",
    timezone23: "-1",
    Rejected: "Rejected",
    Pending: "Pending",
    Approved: "Approved",
    Executing: "Executing",
    ExecutedSuccess: "Executed Success",
    ExecutedFailed: "Executed Failed",
    CustomerWallet: "Customer Wallet",
    MerchantWallet: "Merchant Wallet",
    AdjustCredit: "Adjust Credit",
    Repayment: "Repayment",
    SetCredit: "Set Credit",
    CreditIn: "IN",
    DebitOut: "OUT",
    Adjustment: "Adjustment",
    SetValue: "Set Value",
    MerchantTx: "Merchant Transaction",
    CustomerTx: "Customer Transaction",
    Auto: "Auto",
    ByRequest: "By Request",
    assigned: "Assigned",
    created: "Created",
    deleted: "Deleted",
    Failed: "Failed",
    SSN: "SSN",
    SocialInsurance: "Social Insurance",
    TaxId: "Tax ID",
    IdentityCard: "Hong Kong Identity Card",
    DrivingLicense: "Driving License",
    ShareCode: "Share Code",
    VoterId: "Voter ID",
    Passport: "Passport",
    Other: "Other",
    Fee: "Fee",
    Load: "Load",
    Withdraw: "Withdraw",
    Stucking: "Stucking",
    CustomerLoad: "Customer Load",
    CustomerWithdraw: "Customer Withdraw",
    MerchantDeposit: "Merchant Deposit",
    MerchantWithdraw: "Merchant Withdraw",
    FeeWallet: "Fee Wallet",
    Unknown: "Unknown",
    NewAccount: "New Account",
    CardSale: "Card Sales",
    Purchase: "Purchase",
    Refund: "Refund",
    AccountFunding: "Account Funding",
    BalanceInquiry: "Balance Inquiry",
    P2P: "P2P",
    WalletTransfer: "Wallet Transfer",
    Exchange: "Exchange",
    ExchangeMargin: "Exchange Margin",
    SecondPresentment: "Second Presentment",
    ChargeBack: "Charge Back",
    ExchangeAdjustment: "Exchange Adjustment",
    Maintenance: "Maintenance",
    ChargeOff: "Charge Off",
    Withholding: "Withholding",
    CloseAccount: "Close Account",
    Reward: "Reward",
    Shipping: "Shipping",
    ExpeditedShipping: "Expedited Shipping",
    Addressing: "FPS Registration",
    Settlement: "Settlement",
    Consolidated: "Consolidated",
    Interest: "Interest",
    Cashback: "Cashback",
    MonthlyFee: "Monthly Fee",
    AnnualFee: "Annual Fee",
    FastFund: "Fast Fund",
    LoadReversal: "Load Reversal",
    Expiry: "Expiry",
    MoneyReceive: "Money Receive",
    LatePaymentFee: "Late Payment Fee",
    InWalletPOS: "In Wallet POS",
    OutOfWalletPOS: "Out of Wallet POS",
    InWalletATM: "In Wallet ATM",
    OutOfWalletATM: "Out of Wallet ATM",
    Online: "Online",
    Partner: "Partner",
    Customer: "Customer",
    Internal: "Internal",
    Static: "Static",
    MobileCommerce: "Mobile Commerce",
    OnUs: "On Us",
    OutOfWalletOffline: "Out of Wallet Offline",
    yes: "Yes",
    no: "No",
    Activated: "Activated",
    Assigned: "Assigned",
    Created: "Created",
    Damaged: "Damaged",
    Expired: "Expired",
    Lost: "Lost",
    Replaced: "Replaced",
    Terminated: "Terminated",
    Yes: "Yes",
    No: "No",
    Shipped: "Shipped",
    Sold: "Sold",
    Returned: "Returned",
    Resent: "Resent",
    Invalid: "Invalid",
    Fraud: "Fraud",
    HK: "HK",
    ISS: "ISS",

    FundingAdjustment: "Funding Adjustment",
    TransferIn: "Transfer In",
    LateChargeWaive: "Late Charge Waive",
    InterestWaive: "Interest Waive",
    WithdrawFeeAdjustment: "Withdraw Fee Adjustment",
    WithdrawFeeAdjustmentAgentMarkup: "Withdraw Fee Adjustment Agent Markup",
    FeeAdjustmentOversea: "Fee Adjustment Oversea",
    FeeAdjustmentOverseaAgentMarkup: "Fee Adjustment Oversea Agent Markup",
    FeeAdjustmentGB: "Fee Adjustment GB",
    E6Fee: "E6 Fee",
    WithdrawFeeAdjustmentLocal: "Withdraw Fee Adjustment Local",
    WithdrawFeeAdjustmentLocalAgentMarkup:
      "Withdraw Fee Adjustment Local Agent Markup",
    FeeAdjustmentLocal: "Fee Adjustment Local",
    FeeAdjustmentLocalAgentMarkup: "Fee Adjustment Local Agent Markup",
    SevenEleven: "7-Eleven",
    PurchaseDisputeRefund: "Purchase Dispute Refund",
    WithdrawDisputeRefund: "Withdraw Dispute Refund",
    PurchaseDisputeAdjustment: "Purchase Dispute Adjustment",
    WithdrawDisputeAdjustment: "Withdraw Dispute Adjustment",

    TransferOut: "Transfer Out",

    WithdrawFeeAdjustmentOversea: "Withdraw Fee Adjustment Oversea",
    WithdrawFeeAdjustmentOverseaAgentMarkup:
      "Withdraw Fee Adjustment Oversea Agent Markup",
    RepaymentAdjustment: "Repayment Adjustment",
    newAccount: "New Account",
    FeeAdjustment: "Fee Adjustment",
    Others: "Others",
    TransactionDecline: "Transaction Decline",
    P2p: "P2P",
    Chargeback: "Charge Back",
    Transfer: "Transfer",
    VipServiceFee: "VIP Service Fee",
    Chargeoff: "Charge Off",

    lastWeek: "Last 7 days",
    lastMonth: "Last 30 days",
    last3Month: "Last 90 days",
    Api: "API",
    AdjustManual: "Manual",
    Fps: "FPS",
    FPS: "FPS",
  },

  yubiKeyHook: { enter_yubi_key: "Enter YubiKey" },

  accountSetting: {
    email: "Login Email",
    timezone: "Time Zone",
    language: "Langauge",
  },

  customerAccount: {
    operation: "Operation",
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    partner_name: "Partner Name",
    program_name: "Program Name",
    program_agent_id: "Program Agent ID",
    distributor_agent_id: "Distributor Agent ID",
    customer_no: "Customer No.",
    merchant_customer_reference: "Merchant Customer Reference",
    currency: "Currency",
    max_credit_limit: "Max Credit Limit",
    created_by: "Created By",
    last_modified_by: "Last Modified By",
    details: "Details",
    offering: "Financials",
    hkd: "HK$",
    enabled: "Enabled",
    disabled: "Disabled",
    network: "Network Message",
    monthly_statement: "Monthly Statement",
    month: "Month",
    statement_date: "Statement Date",
    report_name: "Report Name",
    balance: "Balances",
    total_balance: "Total Balance",
    available_balance: "Available Balance",
    excess_credit_of: "Excess Credit of",
    credit_limit: "Credit Limit",
    over_limit_allowance: "Over Limit Allowance",
    resident_address: "Resident Address",
    first_name: "First Name",
    last_name: "Last Name",
    email_address: "Email Address",
    phone_no: "Phone No.",
    date_of_birth: "Date of Birth",
    identification_document_type: "Identification Document Type",
    place_of_issue: "Place of Issue",
    identification_no: "Identification No.",

    issuance_date_of_identification_document:
      "Issuance Date of Identification Document",
    expiry_date_of_identification_document:
      "Expiry Date of Identification Document",
    uploaded_document: "Uploaded Document",

    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    address_line_3: "Address Line 3",
    postal_code: "Postal Code",
    city: "City",
    country: "Country",

    delivery_address: "Delivery Address",

    no_customer_number: "No Customer Number",
    fromDate: "From Date",
    toDate: "To Date",
    limitResultTo: "Limit Result To",

    date: "Date",

    amount: "Amount",
    type: "Type",
    mti: "MTI",
    success: "Success",
    reversal: "Reversal",
    networkMessages: "Network Messages",
    customerNumber: "Customer Number",
  },

  merchantWallet: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    wallet_type: "Wallet Type",
    wallet_name: "Wallet Name",
    currency: "Currency",
    credit_limit: "Credit Limit",
    balance: "Balance",
    created_by: "Created By",
    last_modified_by: "Last Modified By",
    assigned_credit: "Assigned Credit",
    available_credit: "Available Credit",
  },
  customerWallet: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    wallet_type: "Wallet Type",
    wallet_name: "Wallet Name",
    currency: "Currency",
    credit: "Credit",
    created_by: "Created By",
  },
  feeWallet: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    wallet_name: "Wallet Name",
    currency: "Currency",
    balance: "Balance",
    created_by: "Created By",
  },

  merchantDepositRequest: {
    operations: "Operation",
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    order_id: "Order ID",
    approval_progress: "Approval Progress",
    merchant_name: "Merchant Name",
    currency: "Currency",
    status: "Status",
    amount: "Amount",
    created_by: "Created By",
    last_modified_by: "Last Modified By",
    approvalProgressDialogTitle: "Approval Progress",
    approval_sequence: "Approval Sequence",
    permission: "Permission",
    operation_result: "Operation Result",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    remarks: "Remarks",
    create_request: "Create Request",
    create_request_success: "Request created successfully",
    approve_reject_request_btn: "Approve/Reject",
    approve_reject_dialog_title: "Approve/Reject",
    approve_btn: "Approve",
    reject_btn: "Reject",
    confirm_btn: "Confirm",
    no_relevant_permission: "No relevant permission",
    no_same_operator_creator: "The operator and creator must not be the same",
    no_same_approver: "The approvers must not be the same",
    request_approved: "Request approved",
    approve_successful: "Approved successfully",
    request_rejected: "Request rejected",
    Rejected: "Rejected",
    Pending: "Pending",
    Approved: "Approved",
  },

  walletTransaction: {
    creation_time: "Creation Time",
    merchant_name: "Merchant Name",
    order_id: "Order ID",
    customer_no: "Customer No.",
    direction: "Direction",
    wallet_type: "Wallet Type",
    transaction_type: "Transaction Type",
    currency: "Currency",
    previous_credit: "Previous Credit",
    credit_change: "Credit Change",
    post_credit: "Post Credit",
    balance_change: "Balance Change",
    post_balance: "Post Balance",
    from: "From",
    to: "To",
    created_by: "Created By",
  },

  creditAdjustmentRequest: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    order_id: "Order ID",
    repayment_mode: "Repayment Mode",
    adjustment_type: "Adjustment Type",
    merchant_name: "Merchant Name",
    customer_no: "Customer No.",
    currency: "Currency",
    real_time_credit: "Real Time Credit When Requesting",
    real_time_balance: "Real Time Balance When Requesting",
    adjustment_amount: "Adjustment Amount",
    status: "Status",
    created_by: "Created By",
    operations: "Operations",
    retry: "Retry",
    rebroadcast_callback_pmp_message: "Rebroadcast Callback PMP Message",
    rebroadcastSuccess: "Rebroadcast successfully",
    retryDialog: {
      title: "Confirm to resubmit the Balance Adjustment Request?",
      successMsg: "Retried successfully",
    },
  },

  repaymentSchedule: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    scheduled_repayment_time: "Scheduled Repayment Time",
    repayment_order_id: "Repayment Order ID",
    credit_adjustment_order_id: "Credit Adjustment Order ID",
    adjustment_type: "Adjustment Type",
    merchant_name: "Merchant Name",
    customer_no: "Customer No.",
    currency: "Currency",
    amount: "Amount",
    step: "Step",
    status: "Status",
    created_by: "Created By",
    operations: "Operations",
    retry: "Retry",
    rebroadcast_callback_pmp_message: "Rebroadcast Callback PMP Message",
    rebroadcastSuccess: "Rebroadcast successfully",
    retryDialog: {
      title: "Confirm to resubmit the Balance Adjustment Request?",
      successMsg: "Retried successfully",
    },
  },

  cardList: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    partner_name: "Partner Name",
    program_name: "Program Name",
    card_profile_name: "Card Profile Name",
    order_id: "Order ID",
    order_progress: "Order Progress",
    status: "Status",
    created_by: "Created By",
    pre_create_card_request: "Pre-Create Card Request",
    pre_create_card: "Pre-Create Card",
    customer_no: "Customer No.",
    card_no: "Card No.",
    original_card_no: "Original Card No.",
    pan: "PAN (Last 4)",
    reissue_order_id: "Reissue Order ID",
    last_modified_by: "Last Modified By",
    terminate_card: "Terminate Card",
    termination_details: "Termination Details",
    embossed_name: "Embossed Name",
    card_type: "Card Type",
    card_creation_time: "Card Creation Time",
    card_expiry: "Card Expiry",
    order_sequence: "Order Sequence",
    create_request: "Create Request",
    no_of_pre_create_card: "No. of Pre-Create Card",
    e6_card_state: "E6 Card State",
    state_updated_time: "State Updated Time",
    pre_created_card: "Pre-Created Card",
    imported: "Imported",
    blocked: "Blocked",
    reissue: "Reissue",
    reissue_new_card: "Reissue New Card",
    reissue_reason: "Reissue Reason",
    deactivated_original_card: "Deactivated Original Card",
    deactivate_original_card: "Deactivate Original Card",
    deactivated_original_card_on_activation:
      "Deactivated Original Card on Activation",
    card_activation_time: "Card Activation Time",
    operation: "Operation",
    confirm_reissue: "Confirm to reissue new card?",
    confirm_terminate: "Confirm to terminate this card?",
    pin: "Pin",
    samePin: "Same PIN",
    samePan: "Same PAN",
    false: "False",
    true: "True",
    reissue_success: "Created Reissue card order successfully",
    terminate_success: "Terminated successfully",
    terminate_failed: "Terminated Failed",
    termination_reason: "Termination Reason",
    memo: "Memo",
  },

  cardOrderRequest: {
    merchant_name: "Merchant Name",
    order_id: "Order ID",
    program_name: "Program Name",
    customer_no: "Customer No.",
    card_type: "Card Type",
    card_profile_name: "Card Profile Name",
    status: "Status",
    remark: "Remark",
    reissued: "Reissued",
    expiry_date: "Expiry Date",
    reissue_reason: "Reissue Reason",
    original_card_no: "Original Card No.",
    same_pan: "Same PAN",
    same_pin: "Same PIN",
    deactivated_current_card: "Deactivated Current Card",
    deactivated_original_card_on_activation:
      "Deactivated Original Card on Activation",
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    new_card: "New Card",
    create_request_success: "Created new card order successfully",
    searching: "Searching",
    customerNumberNotFound: "Customer Number Not Found",
  },
  preCreateCardRequest: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    merchant_name: "Merchant Name",
    partner_name: "Partner Name",
    program_name: "Program Name",
    card_profile_name: "Card Profile Name",
    order_id: "Order ID",
    order_progress: "Order Progress",
    status: "Status",
    created_by: "Created By",
    // last_modified_by: "Last Modified By",
    pre_create_card_request: "Pre-Create Card Request",
    customer_no: "Customer No.",
    create_request: "Create Request",
    no_of_pre_create_card: "No. of Pre-Create Card",
    remarks: "Remarks",
    zero_card_warning: "No. of Pre-Create Card must be larger than 0",
  },

  balanceAdjustmentRequest: {
    operation: "Operation",
    retry: "Retry",
    approve_reject: "Approve/Reject",
    approval_progress: "Approval Progress",
    approve_reject_dialog_title: "Approve/Reject",
    approve: "Approve",
    reject: "Reject",
    no_same_approver: "The approvers must not be the same",
    no_same_operator_creator: "The operator and creator must not be the same",
    confirm_btn: "Confirm",
    confirm_failure: "Confirm Failure",
    merchant_name: "Merchant Name",
    order_id: "Order ID",
    customer_number: "Customer No.",
    adjustment_type: "Adjustment Type",
    sub_type: "Sub-Type",
    currency: "Currency",
    amount: "Amount",
    status: "Status",
    program_name: "Program Name",

    created_by: "Created By",
    creation_time: "Creation Time",
    last_modified_by: "Last Modified By",
    last_modified_time: "Last Modified Time",

    retry_dialog_title: "Confirm to resubmit the Balance Adjustment Request?",
    confirm_failure_dialog_title:
      "Confirm to fail the Balance Adjustment Request?",
    retry_success: "Retried successfully",
    confirm_failure_success: "Confirm Failure successfully",
    current_available_balance: "Current Available Balance",
    create_request: "Create Request",
    balance_not_enough_warning: "Current Available Balance is not enough",
    created_request_successfully: "Created request successfully",
    customer_number_not_exist: "Customer No. does not exist",
    approvalProgressDialogTitle: "Approval Progress",
    approval_sequence: "Approval Sequence",
    permission: "Permission",
    operation_result: "Operation Result",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    no_relevant_permission: "No relevant permission",
    remarks: "Remarks",
    remark: "Remark",
    memo: "Memo",
    related_transaction_id: "Related Transaction ID",
    request_type: "Request Type",
  },

  cardTransaction: {
    creation_time: "Creation Time",
    merchant_name: "Merchant Name",
    program_name: "Program Name",
    customer_number: "Customer No.",
    card_number: "Card No.",
    pan_last_4: "PAN (Last 4)",
    transaction_id: "Transaction ID",
    transaction_type: "Transaction Type",
    transaction_source: "Transaction Source",
    retrieval_reference_number: "Retrieval Reference No.",

    billing_currency: "Billing Currency",
    billing_amount: "Billing Amount",
    is_authorization: "Is Authorization",
    authorization_code: "Authorization Code",
    completed_transaction: "Completed Transaction",
    acquirer_details: "Acquirer Details",
    acquiring_institution_id: "Acquiring Institution ID",
    acquirer_currency: "Acquirer Currency",
    acquirer_amount: "Acquirer Amount",
    transaction_details: "Transaction Details",
    is_atm: "Is ATM",
    is_in_country: "Is In Country",
    online_transaction: "Online Transaction",
    pos_transaction: "POS Transaction",
    is_reversal: "Is Reversal",
    card_acceptor_details: "Card Acceptor Details",
    merchant_category_codes: "Merchant Category Codes (MCC)",
    card_acceptor_id: "Card Acceptor ID",
    card_acceptor_name: "Card Acceptor Name",
    card_acceptor_city: "Card Acceptor City",
    card_acceptor_country_code: "Card Acceptor Country Code",
    memo: "Memo",
    fee_currency: "Fee Currency",
    fee: "Fee",
    visa_rebate: "Visa Rebate",
    visa_fee: "Visa Fee",
    swap_cost: "Swap Cost",
    system_cost: "System Cost",
    agent_rebate: "Agent Rebate",
    sub_type: "Sub-Type",
    external_reference: "External Reference",
    program_agent_id: "Program Agent ID",
    distributor_agent_id: "Distributor Agent ID",
    hidden: "Hidden",
    ph_transaction_sub_type: "Please select Transaction Type first",
  },
  dailyTransaction: {
    processingDate: "Processing Date",
    settlementService: "Settlement Service",
    e6ProcessingDate: "Processing Date",
    visaProcessingDate: "Processing Date",
    e6SettlementService: "Settlement Service",
    visaSettlementService: "Settlement Service",
    e6OriginalTransactionAmount: "Original Transaction Amount (HKD)",
    visaOriginalTransactionAmount: "Original Transaction Amount (HKD)",
    exchangeRateFee: "Exchange Rate Fee (1.5% Round Down)",
    ukAdjustmentFee: "UK Adjustment Fee (0.5%)",
    fees: "Fees (HKD)",
    interestCharges: "Interest / Charges",
    total: "Total (HKD)",
    valueDate: "Value Date",
    visaCharges: "Visa Charges (i.e. ccy chg) (1%)",
    visaReimbursementFees: "Visa Reimbursement Fees (Rebate)",
    netSettlementAmount: "Net Settlement Amount (HKD)",
    e6: "E6",
    visa: "Visa",
  },
  approvalProgress: {
    Rejected: "Rejected",
    Pending: "Pending",
    Approved: "Approved",
    remarks: "Remarks",
    remark: "Remark",
    approve_btn: "Approve",
    confirm_btn: "Confirm",
    reject_btn: "Reject",
    no_relevant_permission: "No relevant permission",
    no_same_approver: "The approvers must not be the same",
    no_same_operator_creator: "The operator and creator must not be the same",
    request_approved: "Request approved",
    approve_success: "Approve successfully",
    reject_success: "Request Rejected",
    approve_fail: "Approve fail",
    reject_fail: "Reject fail",
    request_rejected: "Request rejected",
    approve_successful: "Approved successfully",
    approvalProgressDialogTitle: "Approval Progress",
  },
  byProgramConsumption: {
    // chart
    chartPanelTitle: "Chart",
    consumptionAmountCountByProgramOverTime:
      "Consumption Amount Count By Program Over Time",
    consumptionAmountCountByProgram: "Consumption Amount Count By Program",
    numberOfConsumptionCountByProgramOverTime:
      "Number Of Consumption Count By Program Over Time",
    numberOfConsumptionCountByProgram: "Number Of Consumption Count By Program",

    // table
    tablePanelTitle: "Table",
    programName: "Program Name",
    totalAmount: "Consumption Amount Count(HKD)",
    totalCount: "Number Of Consumption Count",
    amountCountPerPurchase: "Amount Count Per Purchase",

    // other
    dateRange: "Date Range",
    searchItem: "Program",
    category: "Program",
  },
  byPfhNoConsumption: {
    // chart
    chartPanelTitle: "Chart",
    consumptionAmountCountByPfhNoOverTime:
      "Consumption Amount Count By PFH No. Over Time",
    consumptionAmountCountByPfhNo: "Consumption Amount Count By PFH No.",

    // table
    pfhNo: "PFH no.",
    totalAmount: "Consumption Amount Count(HKD)",
    totalCount: "Consumption Amount Count (HKD)",

    // other
    dateRange: "Date Range",
    searchItem: "PFH no.",
    customerNumber: "PFH no.",
  },
  byMccConsumption: {
    // chart
    chartPanelTitle: "Chart",
    consumptionAmountCountByMccOverTime:
      "Consumption Amount Count By MCC Over Time",
    consumptionAmountCountByMcc: "Consumption Amount Count By MCC",
    numberOfConsumptionCountByMccOverTime:
      "Number Of Consumption Count By MCC Over Time",
    numberOfConsumptionCountByMcc: "Number Of Consumption Count By MCC",

    // table
    mcc: "MCC",
    totalAmount: "Consumption Amount Count(HKD)",
    totalCount: "Number Of Consumption Count",
    amountCountPerPurchase: "Amount Count Per Purchase",

    // other
    dateRange: "Date Range",
    searchItem: "MCC",
    category: "MCC",
  },

  byCountryConsumption: {
    // chart
    chartPanelTitle: "Chart",
    consumptionAmountCountByCountryOverTime:
      "Consumption Amount Count By Country Over Time",
    consumptionAmountCountByCountry: "Consumption Amount Count By Country",
    numberOfConsumptionCountByCountryOverTime:
      "Number Of Consumption Count By Country Over Time",
    numberOfConsumptionCountByCountry: "Number Of Consumption Count By Country",

    // table
    country: "Country",
    totalAmount: "Consumption Amount Count(HKD)",
    totalCount: "Number Of Consumption Count",
    amountCountPerPurchase: "Amount Count Per Purchase",

    // other
    dateRange: "Date Range",
    searchItem: "Country",
    category: "Country",
  },
  byPaymentMethodConsumption: {
    // chart
    chartPanelTitle: "Chart",
    consumptionAmountCountByPaymentMethodOverTime:
      "Consumption Amount Count By Payment Method Over Time",
    consumptionAmountCountByPaymentMethod:
      "Consumption Amount Count By Payment Method",
    numberOfConsumptionCountByPaymentMethodOverTime:
      "Number Of Consumption Count By Payment Method Over Time",
    numberOfConsumptionCountByPaymentMethod:
      "Number Of Consumption Count By Payment Method",

    // table
    paymentMethod: "Payment Method",
    totalAmount: "Consumption Amount Count(HKD)",
    totalCount: "Number Of Consumption Count",
    amountCountPerPurchase: "Amount Count Per Purchase",

    // other
    dateRange: "Date Range",
    searchItem: "Payment Method",
    category: "Payment Method",
  },

  byProgramWithdraw: {
    // chart
    chartPanelTitle: "Chart",
    withdrawalAmountCountByProgramOverTime:
      "Withdrawal Amount Count By Program Over Time",
    withdrawalAmountCountByProgram: "Withdrawal Amount Count By Program",
    numberOfWithdrawalCountByProgramOverTime:
      "Number Of Withdrawal Count By Program Over Time",
    numberOfWithdrawalCountByProgram: "Number Of Withdrawal Count By Program",

    // table
    programName: "Program Name",
    totalAmount: "Withdraw Amount Count(HKD)",
    totalCount: "Number Of Withdrawal Count",
    amountCountPerWithdrawal: "Amount Count Per Withdrawal",

    // other
    dateRange: "Date Range",
    searchItem: "Program",
    category: "Program",
  },
  byPfhNoWithdraw: {
    // chart
    chartPanelTitle: "Chart",
    withdrawalAmountCountByPfhNoOverTime:
      "Withdraw Amount Count By PFH No. Over Time",
    withdrawalAmountCountByPfhNo: "Withdraw Amount Count By PFH No.",

    // table
    pfhNo: "PFH no.",
    totalAmount: "Withdraw Amount Count(HKD)",
    withdrawalAmountCountHkd: "Withdraw amount count (HKD)",

    // other
    dateRange: "Date Range",
    searchItem: "PFH no.",
    customerNumber: "PFH no.",
  },
  byCountryWithdraw: {
    // chart
    chartPanelTitle: "Chart",
    withdrawalAmountCountByCountryOverTime:
      "Withdrawal Amount Count By Country Over Time",
    withdrawalAmountCountByCountry: "Withdrawal Amount Count By Country",
    numberOfWithdrawalCountByCountryOverTime:
      "Number Of Withdrawal Count By Country Over Time",
    numberOfWithdrawalCountByCountry: "Number Of Withdrawal Count By Country",

    // table
    country: "Country",
    totalAmount: "Withdraw Amount Count(HKD)",
    totalCount: "Number Of Withdrawal Count",
    amountCountPerWithdrawal: "Amount Count Per Withdrawal",

    // other
    dateRange: "Date Range",
    searchItem: "Country",
    category: "Country",
  },

  customerSpendingReport: {
    daily_report_title: "Daily Report",
    monthly_report_title: "Monthly Report",
  },

  customerSpendingDailyReport: {
    month: "Month",
    program_agent_id: "Program Agent ID",
    distributor_agent_id: "Distributor Agent ID",
    distributor_agent_name: "Distributor Agent Name",
    pfh_no: "PFH No.",
    program_name: "Program Name",
    currency: "Currency",
    repayment: "Repayment",
    total_transaction_amount: "Total Transaction Amount",
    local_payment: "Local Payment",
    local_payment_pfh_cost: "Local Payment (PFH Cost)",
    local_payment_pa_markup: "Local Payment (PA Markup)",
    local_withdraw: "Local Withdraw",
    local_withdraw_pfh_cost: "Local Withdraw (PFH Cost)",
    local_withdraw_pa_markup: "Local Withdraw (PA Markup)",
    local_refund: "Local Refund",
    local_refund_pfh_cost: "Local Refund (PFH Cost)",
    local_refund_pa_markup: "Local Refund (PA Markup)",
    overseas_payment: "Overseas Payment",
    overseas_payment_pfh_cost: "Overseas Payment (PFH_Cost)",
    overseas_payment_pa_markup: "Overseas Payment (PA Markup)",
    overseas_withdraw: "Overseas Withdraw",
    overseas_withdraw_pfh_cost: "Overseas Withdraw (PFH_Cost)",
    overseas_withdraw_pa_markup: "Overseas Withdraw (PA Markup)",
    overseas_refund: "Overseas Refund",
    overseas_refund_pfh_cost: "Overseas Refund (PFH Cost)",
    overseas_refund_pa_markup: "Overseas Refund (PA Markup)",
    total_pfh_cost: "Total PFH Cost",
    total_pa_markup: "Total PA Markup",
    annual_fee: "Annual Fee",
    visa_rebate: "Visa Rebate",
    pa_total_rebate: "PA Total Rebate",
    settlement_date: "Settlement Date",
  },
  customerSpendingMonthlyReport: {
    month: "Month",
    program_agent_id: "Program Agent ID",
    distributor_agent_id: "Distributor Agent ID",
    distributor_agent_name: "Distributor Agent Name",
    pfh_no: "PFH No.",
    program_name: "Program Name",
    currency: "Currency",
    repayment: "Repayment",
    total_transaction_amount: "Total Transaction Amount",
    local_payment: "Local Payment",
    local_payment_pfh_cost: "Local Payment (PFH Cost)",
    local_payment_pa_markup: "Local Payment (PA Markup)",
    local_withdraw: "Local Withdraw",
    local_withdraw_pfh_cost: "Local Withdraw (PFH Cost)",
    local_withdraw_pa_markup: "Local Withdraw (PA Markup)",
    local_refund: "Local Refund",
    local_refund_pfh_cost: "Local Refund (PFH Cost)",
    local_refund_pa_markup: "Local Refund (PA Markup)",
    overseas_payment: "Overseas Payment",
    overseas_payment_pfh_cost: "Overseas Payment (PFH_Cost)",
    overseas_payment_pa_markup: "Overseas Payment (PA Markup)",
    overseas_withdraw: "Overseas Withdraw",
    overseas_withdraw_pfh_cost: "Overseas Withdraw (PFH_Cost)",
    overseas_withdraw_pa_markup: "Overseas Withdraw (PA Markup)",
    overseas_refund: "Overseas Refund",
    overseas_refund_pfh_cost: "Overseas Refund (PFH Cost)",
    overseas_refund_pa_markup: "Overseas Refund (PA Markup)",
    total_pfh_cost: "Total PFH Cost",
    total_pa_markup: "Total PA Markup",
    annual_fee: "Annual Fee",
    visa_rebate: "Visa Rebate",
    pa_total_rebate: "PA Total Rebate",
    settlement_date: "Settlement Date",
  },

  transactionPostMonitoringReport: {
    settlement_time: "Settlement Time",
    month: "Month",
    merchant_name: "Merchant Name",
    transaction_id: "Transaction ID",
    program_name: "Program Name",
    customer_number: "Customer No.",
    pan_last4: "PAN (Last 4)",
    transaction_type: "Transaction Type",
    transaction_sub_type: "Sub Type",
    acquirer_currency: "Acquirer Currency",
    acquirer_amount: "Acquirer Amount",
    billing_currency: "Billing Currency",
    billing_amount: "Billing Amount",
    authorization_code: "Authorization Code",
    online_transaction: "Online Transaction",
    reversal_transaction: "Reversal Transaction",
    merchant_category_codes: "Merchant Category Codes (MCC)",
    card_acceptor_id: "Card Acceptor ID",
    card_acceptor_name: "Card Acceptor Name",
    card_acceptor_country: "Card Acceptor Country",
    card_acceptor_city: "Card Acceptor City",
  },

  suspiciousTransactionReport: {
    creation_time: "Creation Time",
    transaction_time: "Transaction Time",
    settlement_time: "Settlement Time",
    rule_name: "Rule Name",
    program_name: "Program Name",
    customer_number: "Customer No.",
    transaction_id: "Transaction ID",
    pan_last_4: "PAN (Last 4)",
    transaction_type: "Transaction Type",
    sub_type: "Sub Type",
    ph_transaction_sub_type: "Please select Transaction Type fist",
    payment_method: "Payment Method",
    authorization_code: "Authorization Code",
    rule_version: "Rule Version",
    output_description: "Output Description",
    output_value: "Output Value",
    card_number: "Card No.",
    acquirer_currency: "Acquirer Currency",
    acquirer_amount: "Acquirer Amount",
    billing_currency: "Billing Currency",
    billing_amount: "Billing Amount",
    online_transaction: "Online Transaction",
    merchant_category_codes: "Merchant Category Codes (MCC)",
    card_acceptor_id: "Card Acceptor ID",
    card_acceptor_name: "Card Acceptor Name",
    card_acceptor_country_code: "Card Acceptor Country Code",
    card_acceptor_city: "Card Acceptor City",
    created_by: "Created By",
    memo: "Memo",
  },

  mtiCode: {
    AuthorizationRequest: "Authorization Request",
    AuthorizationResponse: "Authorization Response",
    AuthorizationAdvice: "Authorization Advice",
    AuthorizationAdviceRepeat: "Authorization Advice Repeat",
    IssuerResponsetoAuthorizationAdvice:
      "Issuer Response to Authorization Advice",
    AcquirerFinancialRequest: "Acquirer Financial Request",
    IssuerResponsetoFinancialRequest: "Issuer Response to Financial Request",
    AcquirerFinancialAdvice: "Acquirer Financial Advice",
    AcquirerFinancialAdviceRepeat: "Acquirer Financial Advice Repeat",
    IssuerResponsetoFinancialAdvice: "Issuer Response to Financial Advice",
    BatchUpload: "Batch Upload",
    BatchUploadResponse: "Batch Upload Response",
    AcquirerReversalRequest: "Acquirer Reversal Request",
    AcquirerReversalResponse: "Acquirer Reversal Response",
    AcquirerReversalAdvice: "Acquirer Reversal Advice",
    AcquirerReversalAdviceResponse: "Acquirer Reversal Advice Response",
    BatchSettlementResponse: "Batch Settlement Response",
    NetworkManagementRequest: "Network Management Request",
    NetworkManagementResponse: "Network Management Response",
    NetworkManagementAdvice: "Network Management Advice",
  },
};

export default json;
